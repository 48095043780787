import { paths } from '~/lib/paths'
import { LocaleCode } from '~/types/locales'
import { ModelApiKey } from '~/types/models'

export default function () {
  const { locale } = useI18n()
  const localePath = useLocalePath()

  const localePathByType = (apiKey: string, slug: string) => {
    let path
    switch (apiKey) {
      case 'index':
        path = localePath('/')
        break
      case 'page':
        path = localePath(`/${slug}`)
        break
      case 'bonus_character':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Bonus].path}/${slug}`)
        break
      case 'project':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Project].path}/${slug}`)
        break
      default:
        return null
    }

    return path
  }

  return {
    localePathByType
  }
}
